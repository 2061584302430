<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content>
            <div class="page-content">
                <div class="grid">
                    <div class="row no-gutters page-list-header">
                        <div class="col l-6 m-6 c-12">
                            <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <div class="page-list-action"> 
                                <md-button v-shortkey="['ctrl', 's']" @shortkey="submit(1)" @click="submit(1)" class="md-raised md-primary">Lưu<md-tooltip>Gửi yêu cầu phê duyệt (Ctrl + L)</md-tooltip></md-button>
                                <md-button @click="close()" class="md-raised">Đóng<md-tooltip>Đóng</md-tooltip></md-button>
                            </div>
                        </div>
                    </div>
                    <div class="page-list-body">
                        <div class="form-body">
                            <div class="row">
                                <div class="col l-4 m-4 c-12">
                                    <div class="form-control">
                                        <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                            <label>Nhân viên phụ trách<span class="label-require">(*)</span></label>
                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                            </template>
                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                "{{ term }}" Không tìm thấy!
                                            </template>
                                            <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn nhân viên phụ trách</span>
                                        </md-autocomplete>
                                        <md-button @click="openStaff()" class="md-icon-button">
                                            <md-icon>manage_search</md-icon>
                                            <md-tooltip>Tìm nhân viên</md-tooltip>
                                        </md-button>
                                    </div>
                                </div>
                                <div class="col l-4 m-4 c-12">
                                    <md-field :class="{'md-invalid': submitted && $v.entity.estimateCode.$error }">
                                        <label for="code">Mã dự toán<span class="label-require">(*)</span></label>
                                        <md-input disabled name="code" v-model="entity.estimateCode"></md-input>
                                        <span class="md-error" v-if="submitted && !$v.entity.estimateCode.required">Vui lòng nhập mã dự toán</span>
                                    </md-field>
                                </div>
                                <div class="col l-4 m-4 c-12">
                                    <md-datepicker v-model="entity.estimateDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.estimateDate.$error }">
                                        <label>Ngày dự toán<span class="label-require">(*)</span></label>
                                        <span class="md-error" v-if="submitted && !$v.entity.estimateDate.required">Vui lòng chọn ngày dự toán</span>
                                    </md-datepicker>
                                </div>
                                
                            </div>
                            <div class="row">
                                <div class="col l-8 m-8 c-12">
                                    <md-field>
                                        <label for="note">Nội dung</label>
                                        <md-input name="note" v-model="entity.note"></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-4 m-4 c-12">
                                    <div style="display:flex; width: 100%;">
                                        <div style="width: 100%;">
                                            <md-field>
                                                <label for="note">Tổng dự toán</label>
                                                <md-input name="note" :value="formatNumber(entity.estimateAmount)" ></md-input>
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-body form-tab">
                            <div class="row">
                                <div class="col l-12 m-12 c-12">
                                    <md-tabs>
                                        <md-tab id="tab-detail" class="tab-content" md-label="Chi phí">
                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col l-12 m-12 c-12">
                                                        <div class="group-box" style="margin-top:20px;padding-bottom: 0 !important;">
                                                            <div class="group-title">DỰ TOÁN CHI PHÍ ĐIỀU ĐỘNG</div>
                                                            <div class="box-content">
                                                                <div class="tool-bar" style="margin-top: 10px !important;">
                                                                    <md-button @click="addRow(6)" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                                </div>
                                                                <table class="data-table-2"> 
                                                                    <thead> 
                                                                        <tr> 
                                                                            <th style="width:50px;">#</th> 
                                                                            <th style="width:90px;">Hành động</th>
                                                                            <th style="width:250px;">Lệnh điều động<span class="label-require">(*)</span></th> 
                                                                            <th class="left" style="width:200px">Đơn vị<span class="label-require">(*)</span></th>
                                                                            <th class="left" style="width:250px;">Tên phí<span class="label-require">(*)</span></th>
                                                                            <th style="width:150px;">Thành tiền</th>
                                                                            <th class="left" style="width:200px;">Nội dung</th>
                                                                        </tr>
                                                                    </thead> 
                                                                    <tbody v-if="loading == true" style="height: 100px;"> 
                                                                        <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                                    </tbody>
                                                                    <tbody v-if="loading == false"> 
                                                                        <tr v-for="(obj, index) in entity.details.filter(x => x.estimateType == 6)" :key="obj.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                            <td class="center">{{index + 1}}</td>
                                                                            <td class="grid-action">
                                                                                <a @click="delRow(obj)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                            </td>
                                                                            <td class="center">
                                                                                <div class="grid-control">
                                                                                    <input v-model="obj.jobEstimate.estimateCode" class="form-control" type="text">
                                                                                    <md-button @click="openJobEstimate(obj.id)" class="md-icon-button">
                                                                                        <md-icon>manage_search</md-icon>
                                                                                        <md-tooltip>Tìm lệnh điều động</md-tooltip>
                                                                                    </md-button>
                                                                                </div>
                                                                            </td> 
                                                                            <td class="left">
                                                                                {{ obj.unit.unitName }}
                                                                            </td>
                                                                            <td class="left">
                                                                                {{ obj.fee.feeName }}
                                                                            </td> 
                                                                            <td class="right">
                                                                                {{ formatNumber(obj.estimateAmount) }}
                                                                            </td>
                                                                            <td class="center">
                                                                                <div class="group-control">
                                                                                    <input v-model="obj.estimateNote" class="form-control" type="text">
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody> 
                                                                </table> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col l-12 m-12 c-12">
                                                        <div class="group-box" style="margin-top:20px;padding-bottom: 0 !important;">
                                                            <div class="group-title">DỰ TOÁN CHI PHÍ KHÁC</div>
                                                            <div class="box-content">
                                                                <div class="tool-bar" style="margin-top: 10px !important;">
                                                                    <md-button @click="addRow(7)" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                                </div>
                                                                <table class="data-table-2"> 
                                                                    <thead> 
                                                                        <tr> 
                                                                            <th style="width:100px;">#</th> 
                                                                            <th style="width:100px;">Hành động</th>
                                                                            <th style="width:13%;">Đơn vị<span class="label-require">(*)</span></th>
                                                                            <th style="width:21%;">Tên phí<span class="label-require">(*)</span></th> 
                                                                            <th class="left" style="width:10%;">Loại<span class="label-require">(*)</span></th> 
                                                                            <th style="width:8%;">Số lượng<span class="label-require">(*)</span></th>
                                                                            <th style="width:12%;">Đơn giá<span class="label-require">(*)</span></th>
                                                                            <th style="width:10%;">VAT</th>
                                                                            <th style="width:12%;">Thành tiền</th>
                                                                        </tr> 
                                                                    </thead> 
                                                                    <tbody v-if="loading == true" style="height: 100px;"> 
                                                                        <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                                    </tbody>
                                                                    <tbody v-if="loading == false"> 
                                                                        <tr v-for="(item, index) in entity.details.filter(x => x.estimateType == 7)" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                            <td class="center">{{index + 1}}</td>
                                                                            <td class="grid-action">
                                                                                <a @click="delRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                            </td>
                                                                            <td class="center">
                                                                                <div class="grid-control">
                                                                                    <input v-model="item.unit.unitName" class="form-control" type="text">
                                                                                    <md-button @click="openUnit(item.id)" class="md-icon-button">
                                                                                        <md-icon>manage_search</md-icon>
                                                                                        <md-tooltip>Tìm đơn vị</md-tooltip>
                                                                                    </md-button>
                                                                                </div>
                                                                            </td>
                                                                            <td class="center">
                                                                                <div class="grid-control">
                                                                                    <input v-model="item.fee.feeName" class="form-control" type="text">
                                                                                    <md-button @click="openFee(item.id)" class="md-icon-button">
                                                                                        <md-icon>manage_search</md-icon>
                                                                                        <md-tooltip>Tìm phí</md-tooltip>
                                                                                    </md-button>
                                                                                </div>
                                                                            </td> 
                                                                            <td class="center">
                                                                                <md-field>
                                                                                    <md-select @md-selected="calSum(item)" v-model="item.type" name="type" id="type">
                                                                                        <md-option v-for="item in types" :key="'type' + item.value" :value="item.value">{{item.text}}</md-option>
                                                                                    </md-select>
                                                                                </md-field>
                                                                            </td>
                                                                            <td class="center">
                                                                                <div class="group-control">
                                                                                    <number @change="calSum(item)" v-model="item.quantity" v-bind="numberF" class="form-control currency"></number> 
                                                                                </div>
                                                                            </td>
                                                                            <td class="center">
                                                                                <div class="group-control">
                                                                                    <number @change="calSum(item)" v-model="item.estimatePrice" v-bind="currencyF" class="form-control currency"></number> 
                                                                                </div>
                                                                            </td>
                                                                            <td class="center">
                                                                                <div class="group-control">
                                                                                    <number @change="calSum(item)" v-model="item.estimateVat" v-bind="vatF" class="form-control currency"></number> 
                                                                                </div>
                                                                            </td>
                                                                            <td class="center">
                                                                                <div class="group-control">
                                                                                    <number :disabled="true" v-model="item.estimateAmount" v-bind="currencyF" class="form-control currency"></number> 
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody> 
                                                                </table> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </md-tab>
                                        <md-tab id="tab-approve" class="tab-content" md-label="Phê duyệt">
                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col l-12 m-12 c-12">
                                                        <div class="tool-bar">
                                                            <md-button @click="addApproveRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                        </div>
                                                        <table class="data-table-2"> 
                                                            <thead> 
                                                                <tr> 
                                                                    <th style="width:100px;">#</th> 
                                                                    <th style="width:15%;">Bộ phận/phòng</th> 
                                                                    <th style="width:20%;">Người phê duyệt</th> 
                                                                    <th style="width:20%;">Tiêu đề</th>
                                                                    <th style="width:20%;">Ghi chú</th> 
                                                                    <th style="width:15%;">Thứ tự</th> 
                                                                    <th style="width:150px">Hành động</th> 
                                                                </tr> 
                                                            </thead> 
                                                            <tbody v-if="loading == true" style="height: 100px;"> 
                                                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                            </tbody>
                                                            <tbody v-if="loading == false"> 
                                                                <tr v-for="(item, index) in entity.approveList" :key="'approve-' + item.staffId" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                    <td class="center">{{index + 1}}</td>
                                                                    <td class="center">
                                                                        <div class="grid-control">
                                                                            <input v-model="item.dept.name" :class="{'is-error': $v.entity.approveList.$each[index].deptId.$error}" class="form-control" type="text">
                                                                            <md-button @click="openDept(item.id)" class="md-icon-button">
                                                                                <md-icon>manage_search</md-icon>
                                                                                <md-tooltip>Tìm bộ phận</md-tooltip>
                                                                            </md-button>
                                                                        </div>
                                                                    </td> 
                                                                    <td class="center">
                                                                        <div class="grid-control">
                                                                            <input v-model="item.staff.fullName" :class="{'is-error': $v.entity.approveList.$each[index].staffId.$error}" class="form-control" type="text">
                                                                            <md-button @click="openApprove(item.id)" class="md-icon-button">
                                                                                <md-icon>manage_search</md-icon>
                                                                                <md-tooltip>Tìm nhân viên</md-tooltip>
                                                                            </md-button>
                                                                        </div>
                                                                    </td> 
                                                                    <td class="center">
                                                                        <div class="group-control">
                                                                            <input v-model="item.title" class="form-control" type="text">
                                                                        </div>
                                                                    </td>
                                                                    <td class="center">
                                                                        <div class="group-control">
                                                                            <input v-model="item.note" class="form-control" type="text">
                                                                        </div>
                                                                    </td>
                                                                    <td class="center">
                                                                        <div class="group-control">
                                                                            <input v-model="item.orderSort" class="form-control" type="number" style="text-align: center;">
                                                                        </div>
                                                                    </td> 
                                                                    <td class="grid-action">
                                                                        <a @click="delApproveRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                    </td> 
                                                                </tr> 
                                                            </tbody> 
                                                        </table> 
                                                    </div>
                                                </div>
                                            </div>
                                        </md-tab>
                                    </md-tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <staffList ref="staffList" @close="closeStaff"/>
                <feeList ref="feeList" @close="closeFee"/>
                <approveList ref="approveList" @close="closeApprove"/>
                <deptList ref="deptList" @close="closeDept"/>
                <unitList ref="unitList" @close="closeUnit"/>
                <jobEstimateList ref="jobEstimateList" @close="closeJobEstimate"/>
            </div>
        </md-content>
    </md-dialog>
</template>

<script>
import staffList from '../../../components/popup/_StaffList.vue';
import feeList from '../../../components/popup/_FeeList.vue';
import approveList from '../../../components/popup/_StaffList.vue';
import deptList from '../../../components/popup/_DepartmentList.vue';
import unitList from '../../../components/popup/_UnitList.vue';
import common from '../../../mixins';
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import messageBox from '../../../utils/messageBox';
import approveSettingService from '../../../api/approveSettingService';
import staffService from '../../../api/staffService';
import orderHeavyEstimateService from '../../../api/orderHeavyEstimateService';
import jobEstimateList from '@/components/popup/_JobHeavyEstimateList.vue';

export default ({
    components: {
        staffList,
        feeList,
        approveList,
        deptList,
        unitList,
        jobEstimateList
    },
    data() {
        return {
            showDialog: false,
            id: 0,
            title: '',
            submitted: false,
            loading: false,
            staffName: '',
            staffs: [],
            numberF: common.numberF,
            vatF: common.vatF,
            currencyF: common.currencyF2,
            types: common.typeFeeHeavy,
            order: null,
            entity: { id: 0, orderId: 0, order: { }, details: [], approveList: [], estimateCode: '', estimateDate: common.dateNow, staffId: 0, status: 0, estimateAmount: 0, note: '' },
            detail: { id: 'id_' + common.getFakeId(), orderId: 0, fee: { feeCode: '', feeName: '' }, feeId: 0, type: 1, quantity: 0, price: 0, vat: 0, amount: 0, note: '' },
            approve: { id: 'id_' + common.getFakeId(), dept: { code: '', name: '' }, deptId: 0, staff: { fullName: '', code: '' }, staffId: 0, title: 'Người đề nghị', note: '', orderSort: 1, status: 1, ApprovedAt: null },
        }
    },
    created(){
        this.entity.orderId = parseFloat(this.$route.params.id);
    },
    methods: {
        ...mapActions('common', ['setLoading']),

        calSum(item){
            const selected = this.entity.details.findIndex(x => x.id == item.id);
            let price = parseFloat(this.entity.details[selected].estimatePrice);
            let vat = price * (parseFloat(this.entity.details[selected].estimateVat) / 100);
            this.entity.details[selected].estimateAmount = Math.round(price + vat) * parseFloat(this.entity.details[selected].quantity);

            this.calSummary();
        },

        calSummary(){
            this.entity.estimateAmount = 0;
            for (var i = 0; i < this.entity.details.length; i++) {
                this.entity.estimateAmount = parseFloat(this.entity.estimateAmount) + parseFloat(this.entity.details[i].estimateAmount);
            }
        },

        open(id, order){
            this.submitted = false;
            this.order = order;
            this.id = id;
            if(id > 0){
                this.title = 'Cập nhật dự toán';
                this.getById();
            }
            else{
                this.entity = { id: 0, orderId: 0, order: { }, details: [], approveList: [], estimateCode: '', estimateDate: common.dateNow, staffId: 0, status: 0, estimateAmount: 0, note: '' };
                this.title = 'Thêm mới dự toán';
                this.addRow();
                this.getByAccount();
                this.entity.approveList.push(this.approve);
                this.getApproveSetting();
            }
            this.showDialog = true;
        },
        
        close(){
            this.showDialog = false;
        },

        addRow(type){
            if(type == 6){
                let item = { id: 'id_' + common.getFakeId(), jobEstimate: { estimateCode: '' }, jobEstimateId: 0, estimateType: type, unitId: 37, unit: { unitCode: '', unitName: 'Lệnh điều động' }, orderId: this.$route.params.id, fee: { feeCode: '', feeName: 'Chi phí điều động phương tiện' }, feeId: 140, type: 1, quantity: 0, estimatePrice: 0, estimateVat: 0, estimateAmount: 0, estimateNote: '' };
                this.entity.details.push(item);
            }
            else {
                let item = { id: 'id_' + common.getFakeId(), estimateType: type, unitId: 0, unit: { unitCode: '', unitName: '' }, orderId: this.$route.params.id, fee: { feeCode: '', feeName: '' }, feeId: 0, type: 1, quantity: 0, estimatePrice: 0, estimateVat: 0, estimateAmount: 0, estimateNote: '' };
                this.entity.details.push(item);
            }
        },
        
        delRow(item){
            const index = this.entity.details.findIndex(x => x.id == item.id);
            this.entity.details.splice(index, 1);
            this.calSummary();
        },

        //JobEstimate
        closeJobEstimate(item){
            const selected = this.entity.details.findIndex(x => x.id == this.selectedId);
            this.entity.details[selected].jobEstimate = item;
            this.entity.details[selected].jobEstimateId = item.id;
            this.entity.details[selected].quantity = 1;
            this.entity.details[selected].estimatePrice = item.estimateAmount;
            this.entity.details[selected].estimateVat = 0;
            this.entity.details[selected].estimateAmount = item.estimateAmount;
            this.entity.details[selected].estimateNote = item.note;
            this.calSummary();
            this.$refs.jobEstimateList.close();
            this.selectedId = '';
            console.log( this.entity.details[selected])
        },

        openJobEstimate(id){
            this.selectedId = id;
            this.$refs.jobEstimateList.open(this.order.id);
        },

        submit(status){
            this.entity.orderId = parseFloat(this.$route.params.id);
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            if(this.entity.details.length > 0){
                this.entity.details = this.entity.details.filter(item => !(item.feeId == 0));
                const typeOfTransport = parseFloat(this.order.details[0].typeOfTransport);
                const goodsTypeId = parseFloat(this.order.details[0].goodsTypeId);
                this.entity.details.forEach(function(item, index, self) {
                    if(!common.isNumeric(self[index].id)){
                        self[index].id = 0;
                    }
                    self[index].goodsTypeId = goodsTypeId;
                    self[index].transportTypeId = typeOfTransport;
                });
            }
            if(this.entity.approveList.length > 0){
                this.entity.approveList = this.entity.approveList.filter(item => !(item.staffId == 0));
                this.entity.approveList.forEach(function(item, index, self) {
                    if(!common.isNumeric(self[index].id)){
                        self[index].id = 0;
                    }
                });
            }
            if(this.entity.details.length <= 0){
                messageBox.showWarning("Vui lòng thêm chi tiết dự toán");
                return;
            }
            else{
                this.entity.details.forEach(function(item, index, self) {
                    self[index].type = parseInt(self[index].type);
                });
            }
            if(this.entity.approveList.length < 1){
                messageBox.showWarning("Vui lòng thêm phê duyệt");
                return;
            }
            this.entity.status = status;
            if(this.id > 0){
                this.edit();
            }
            else{
                this.add();
            }
        },

        add(){
            this.setLoading(true);
            orderHeavyEstimateService.add(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Thêm mới thành công");
                    this.$emit('refresh');
                    this.showDialog = false;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        edit(){
            this.setLoading(true);
            orderHeavyEstimateService.edit(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Cập nhật thành công");
                    this.$emit('refresh');
                    this.showDialog = false;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        getById(){
            this.setLoading(true);
            orderHeavyEstimateService.getById(this.id).then((response) => {
                if(response.statusCode == 200){
                    this.entity = response.data;
                    this.staffName = response.data.staff.fullName;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        //Unit
        closeUnit(item){
            const selected = this.entity.details.findIndex(x => x.id == this.selectedId);
            this.entity.details[selected].unit = item;
            this.entity.details[selected].unitId = item.id;
            this.$refs.unitList.close();
            this.selectedId = '';
        },

        openUnit(id){
            this.selectedId = id;
            this.$refs.unitList.open(2);
        },
        
        //Fee
        closeFee(item){
            const selected = this.entity.details.findIndex(x => x.id == this.selectedId);
            this.entity.details[selected].fee = item;
            this.entity.details[selected].feeId = item.id;
            this.$refs.feeList.close();
            this.selectedId = '';
        },

        openFee(id){
            this.selectedId = id;
            this.$refs.feeList.open(2);
        },

        getByAccount(){
            this.setLoading(true);
            staffService.getByAccount().then((response) => {
                if(response.statusCode == 200){
                    if(response.data != null){
                        this.entity.staff = response.data;
                        this.entity.staffId = response.data.id;
                        this.entity.deptId = response.data.departmentId;
                        this.staffName = response.data.fullName;
                        this.currentUser = response.data;
                        this.approve.staffId = response.data.id;
                        this.approve.staff = response.data;
                        this.approve.deptId = response.data.department.id;
                        this.approve.dept = response.data.department;
                        this.getCode(this.entity.staffId);
                    }
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        //Get Approve Setting
        getApproveSetting(){
            approveSettingService.getSetting(common.approveType.ordersEstimate).then((response) => {
                if(response.statusCode == 200){
                    if(response.data != null && response.data.length > 0)
                    {
                        for (let i = 0; i < response.data.length; i++) {
                            let setting = response.data[i];
                            let approve = { id: 'id_' + common.getFakeId(), dept: { code: '', name: '' }, deptId: 0, staff: {}, staffId: {}, title: 'Người đề nghị', typeCode: String(common.approveType.repairEstimate), orderSort: 1, status: 1, ApprovedAt: null };
                            approve.id = 'id_' + common.getFakeId() + i;
                            approve.staffId = setting.staffId;
                            approve.deptId = setting.deptId;
                            approve.dept.code = setting.department.code;
                            approve.dept.name = setting.department.name;
                            approve.staff.code = setting.staff.code;
                            approve.staff.fullName = setting.staff.fullName;
                            approve.title = setting.title;
                            approve.orderSort = setting.orderSort + 1;
                            
                            this.entity.approveList.push(approve);
                        }
                    }
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally();
        },

        //Approve
        delApproveRow(item){
            const index = this.entity.approveList.findIndex(x => x.id == item.id);
            this.entity.approveList.splice(index, 1);
        },

        addApproveRow(){
            let order = 1;
            if(this.entity.approveList != null){
                order = this.entity.approveList.length + 1;
            }
            let item = { id: 'id_' + common.getFakeId(), status: 1, dept: { code: '', name: '' }, deptId: 0, staff: { fullName: '', code: '' }, staffId: 0, title: '', typeCode: String(common.approveType.repairEstimate), orderSort: order, ApprovedAt: null };
            this.entity.approveList.push(item);
        },

        closeApprove(item){
            const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
            this.entity.approveList[selected].staff = item;
            this.entity.approveList[selected].staffId = item.id;
            this.$refs.approveList.close();
            this.selectedId = '';
        },

        openApprove(id){
            this.selectedId = id;
            const index = this.entity.approveList.findIndex(x => x.id == id);
            this.deptId = this.entity.approveList[index].deptId;
            this.$refs.approveList.open(this.deptId);
        },

        //DEPT
        closeDept(item){
            const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
            this.entity.approveList[selected].dept = item;
            this.entity.approveList[selected].title = item.name;
            this.entity.approveList[selected].deptId = item.id;
            if(item.managerId > 0){
                this.entity.approveList[selected].staff = item.manager;
                this.entity.approveList[selected].staffId = item.managerId;
            }
            this.$refs.deptList.close();
            this.selectedId = '';
        },

        openDept(id){
            this.selectedId = id;
            this.$refs.deptList.open();
        },

        getCode(staffId){
            this.setLoading(true);
            orderHeavyEstimateService.getCode(staffId).then((response) => {
                if(response.statusCode == 200){
                    this.entity.estimateCode = response.data.code;
                    this.entity.estimateNumber = response.data.number;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        //Staff
        closeStaff(item){
            this.staffName = item.fullName;
            this.entity.staffId = item.id;
            this.entity.deptId = item.departmentId;
            this.entity.approveList[0].staffId = item.id;
            this.entity.approveList[0].deptId = item.department.id;
            this.entity.approveList[0].dept.code = item.department.code;
            this.entity.approveList[0].dept.name = item.department.name;
            this.entity.approveList[0].staff.code = item.code;
            this.entity.approveList[0].staff.fullName = item.fullName;
            this.entity.approveList[0].title = "Người đề nghị";
            this.getCode(item.id);
            this.$refs.staffList.close();
        },

        getStaffSelected(item){
            this.entity.staffId = item.id;
            this.entity.deptId = item.departmentId;
            this.staffName = item.fullName;
            this.entity.approveList[0].staffId = item.id;
            this.entity.approveList[0].deptId = item.department.id;
            this.entity.approveList[0].dept.code = item.department.code;
            this.entity.approveList[0].dept.name = item.department.name;
            this.entity.approveList[0].staff.code = item.code;
            this.entity.approveList[0].staff.fullName = item.fullName;
            this.entity.approveList[0].title = "Người đề nghị";
            this.getCode(item.id);
        },

        openStaff(){
            this.$refs.staffList.open();
        },

        getStaffs(searchTerm){
            let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
            staffService.getStaffs(search).then((response) => {
                if(response.statusCode == 200){
                    this.staffs = response.data.items;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally();
        },
    },
    watch: { 
        staffName: function (val) { 
            if(val == ''){
                this.entity.staffId = 0;
            }
        },
    },
    validations: {
        entity: {
            estimateCode: { required },
            staffId: {
                required,
                isSelected(value) {
                    if (value > 0) {
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            },
            estimateDate: { required },
            approveList: {
                $each: {
                    deptId: {
                        required,
                        isSelected(value) {
                            if (value > 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    },
                    staffId: {
                        required,
                        isSelected(value) {
                            if (value > 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    },
                }
            },
            
        }
    }
})
</script>