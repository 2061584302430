<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-if="tab == 'tab-info'" v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="form-body">
                    <md-tabs>
                        <md-tab @click="onTabClick('tab-info')" id="tab-info" class="tab-content" md-label="Thông tin đơn hàng">
                            <div class="row">
                                <div class="col l-3 m-3 c-12">
                                    <md-field>
                                        <label for="project">Dự án<span class="label-require">(*)</span></label>
                                        <md-input disabled name="project" v-model="entity.project.projectCode"></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-3 m-3 c-12">
                                    <md-field :class="{'md-invalid': submitted && $v.entity.orderCode.$error }">
                                        <label for="orderCode">Mã đơn hàng<span class="label-require">(*)</span></label>
                                        <md-input disabled name="orderCode" v-model="entity.orderCode"></md-input>
                                        <span class="md-error" v-if="submitted && !$v.entity.orderCode.required">Vui lòng nhập mã đơn hàng thường</span>
                                    </md-field>
                                </div>
                                <div class="col l-3 m-3 c-12">
                                    <div class="form-control">
                                        <md-field :class="{'md-invalid': submitted && $v.entity.planId.$error }">
                                            <label>Loại hình<span class="label-require">(*)</span></label>
                                            <md-select v-model="entity.planId">
                                                <md-option v-for="item in typeList" :key="'type-' + item.id" :value="item.id">{{item.text}}</md-option>
                                            </md-select>
                                            <span class="md-error" v-if="submitted && !$v.entity.planId.isSelected">Vui lòng chọn loại hình vận chuyển</span>
                                        </md-field>
                                    </div>
                                </div>
                                
                                <div class="col l-3 m-3 c-12">
                                    <md-datepicker v-model="entity.orderDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.orderDate.$error }">
                                        <label>Ngày lập<span class="label-require">(*)</span></label>
                                        <span class="md-error" v-if="submitted && !$v.entity.orderDate.required">Vui lòng chọn ngày tạo</span>
                                    </md-datepicker>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col l-3 m-3 c-12">
                                    <md-field>
                                        <label for="customer">Khách hàng<span class="label-require">(*)</span></label>
                                        <md-input disabled name="customer" v-model="entity.customer.companyAlias"></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-3 m-3 c-12">
                                    <md-field :class="{'md-invalid': submitted && $v.entity.branchId.$error }">
                                        <label for="branchId">Chi nhánh<span class="label-require">(*)</span></label>
                                        <md-select v-model="entity.branchId" name="branchId" id="branchId">
                                            <md-option value="0">Chọn chi nhánh</md-option>
                                            <md-option v-for="b in branchs" :key="b.id" :value="b.id">{{b.branchName}}</md-option>
                                        </md-select>
                                        <span class="md-error" v-if="submitted && !$v.entity.branchId.isSelected">Vui lòng chọn chi nhánh</span>
                                    </md-field>
                                </div>
                                <div class="col l-3 m-3 c-12">
                                    <div class="form-control">
                                        <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                            <label>Nhân viên<span class="label-require">(*)</span></label>
                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                            </template>
                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                "{{ term }}" Không tìm thấy!
                                            </template>
                                            <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn nhân viên</span>
                                        </md-autocomplete>
                                        <md-button @click="openStaff()" class="md-icon-button">
                                            <md-icon>manage_search</md-icon>
                                            <md-tooltip>Tìm nhân viên</md-tooltip>
                                        </md-button>
                                    </div>
                                </div>
                                <div class="col l-3 m-3 c-12">
                                    <md-datepicker v-model="entity.orderDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.orderDate.$error }">
                                        <label>Ngày nhận hàng<span class="label-require">(*)</span></label>
                                        <span class="md-error" v-if="submitted && !$v.entity.orderDate.required">Vui lòng chọn ngày tạo</span>
                                    </md-datepicker>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col l-3 m-3 c-12">
                                    <div class="form-control">
                                        <md-field :class="{'md-invalid': submitted && $v.entity.contractId.$error }">
                                            <label>Hợp đồng<span class="label-require">(*)</span></label>
                                            <md-select v-model="entity.contractId">
                                                <md-option :value="0">Chọn hợp đồng</md-option>
                                                <md-option v-for="item in contracts" :key="'contract-' + item.id" :value="item.id">{{item.contractCode}}</md-option>
                                            </md-select>
                                            <span class="md-error" v-if="submitted && !$v.entity.contractId.isSelected">Vui lòng chọn hợp đồng</span>
                                        </md-field>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col l-12 m-12 c-12">
                                    <div class="tool-bar">
                                        <md-button @click="addOrderItem()" class="md-primary"><md-icon>add_card</md-icon> Thêm hàng hóa<md-tooltip>Thêm hàng vận chuyển</md-tooltip></md-button>
                                    </div>
                                    <div id="table-scroll" class="table-scroll" style="height: calc(100vh - 326px); overflow-x: auto; width: 100%;">
                                        <table class="data-table-2" style="width: 100% !important;"> 
                                            <thead> 
                                                <tr> 
                                                    <th style="width:60px;">#</th> 
                                                    <th style="width:10%;">Hành động</th>
                                                    <th style="width:15%;">PkgNo</th>
                                                    <th style="width:18%;">Tên hàng</th>
                                                    <th style="width:15%;">Ngày giao/nhận</th>
                                                    <th style="width:10%;">Đơn vị</th> 
                                                    <th style="width:15%;">Loại hình</th> 
                                                    <th style="width:15%;">Loại hàng</th>
                                                </tr>
                                            </thead> 
                                            <tbody v-if="loadding == true" style="height: 100px;"> 
                                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                            </tbody>
                                            <tbody v-if="loadding == false && entity.details.length > 0"> 
                                                <tr v-for="(item, index) in entity.details" :key="'item-' + index" :class="{ 'odd': index % 2 !== 0 }"> 
                                                    <td class="center">{{index + 1}}</td>
                                                    <td class="action" style="text-align: center">
                                                        <md-button @click="editOrderItem(item)" class="md-fab md-mini md-second">
                                                            <md-icon>edit</md-icon>
                                                            <md-tooltip>Sửa</md-tooltip>
                                                        </md-button>
                                                        <md-button v-on:click="delOrderItem(item.id)" class="md-fab md-mini md-plain">
                                                            <md-icon>clear</md-icon>
                                                            <md-tooltip>Xóa</md-tooltip>
                                                        </md-button>
                                                    </td> 
                                                    <td class="left">
                                                        {{item.pkgNo}}
                                                    </td>
                                                    <td class="left">
                                                        {{item.goods.goodsName}}
                                                    </td>
                                                    <td class="center">
                                                        {{item.dateOfDelivery}}
                                                    </td> 
                                                    <td class="left">
                                                        {{item.unit.unitName}}
                                                    </td>
                                                    <td class="left">
                                                        {{ getTypeOfTransport(item.typeOfTransport) }}
                                                    </td> 
                                                    <td class="left">
                                                        {{ getGoodsTypeHeavy(item.goodsTypeId) }}
                                                    </td>
                                                </tr> 
                                            </tbody> 
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </md-tab>
                        <md-tab :md-disabled="entity.id == 0" @click="onTabClick('tab-shipment')" id="tab-shipment" class="tab-content" md-label="Shipment">
                            <shipmentForm />
                        </md-tab>
                        <md-tab :md-disabled="entity.id == 0" @click="onTabClick('tab-customs')" id="tab-customs" class="tab-content" md-label="Khai báo hải quan">
                            <customs />
                        </md-tab>
                        <md-tab :md-disabled="entity.id == 0" @click="onTabClick('tab-job')" id="tab-job" class="tab-content" md-label="Kế hoạch điều động">
                            <jobList v-if="tab == 'tab-job'" :order="entity" />
                        </md-tab>
                        <md-tab :md-disabled="entity.id == 0" @click="onTabClick('tab-estimate')" id="tab-estimate" class="tab-content" md-label="Dự toán">
                            <estimate :order="entity" />
                        </md-tab>
                    </md-tabs>
                </div>
            </div>
        </div>
        <staffList ref="staffList" @close="closeStaff"/>
        <addForm ref="addForm" @close="closeOrderItem" @edit="closeOrderEdit" />
   </div>
</template>
<script>
    import branchService from '../../../api/branchService';
    import orderHeavyService from '../../../api/orderHeavyService';
    import projectService from '../../../api/projectService';
    import staffService from '../../../api/staffService';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import messageBox from '../../../utils/messageBox';
    import common from '../../../mixins'; 
    import staffList from '../../../components/popup/_StaffList.vue';
    import addForm from './AddItem.vue';
    import shipmentForm from './Shipment.vue';
    import customs from './Customs.vue';
    import estimate from './Estimate.vue';
    import jobList from './Job.vue'
    
    export default ({
        components: {
            jobList,
            customs,
            estimate,
            staffList,
            addForm,
            shipmentForm
        },
        metaInfo: {
            title: 'Thêm/Cập nhật đơn hàng siêu trường siêu trọng'
        },
        data() {
            return {
                title: '',
                id: 0,
                tab: 'tab-info',
                submitted: false,
                typeName: '',
                types: [],
                loadding: false,
                typeList: common.typeOfTransportHeavy,
                planHeavyList: common.planHeavyList,
                entity: { id: 0, details: [], branchId: 0, contractId: 0, contract: { contractCode: '' }, projectId: 0, project: { projectCode: '' }, customerId: 0, customer: { companyAlias: '' }, orderCode: '', orderDate: common.dateNow, planId: 1 },
                contracts: [],
                staffName: '',
                staffs: [],
                branchs: [],
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
                this.title = 'Cập nhật đơn hàng siêu trường siêu trọng';
                this.getById();
            }
            else{
                this.entity.projectId = this.$route.query.projectId;
                this.getProjectById();
                this.title = 'Thêm mới đơn hàng siêu trường siêu trọng';
            }
            this.getByAccount();
            this.getBranchs();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            editOrderItem(item){
                this.$refs.addForm.open(item);
            },

            getCode(staffId){
                orderHeavyService.getCode(staffId).then((response) => {
                    if(response.statusCode == 200){
                        this.entity.orderCode = response.data.code;
                        this.entity.orderNumber = response.data.number;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            getBranchs(){
                this.setLoading(true);
                branchService.getAll().then((response) => {
                    if(response.statusCode == 200){
                        this.branchs = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            closeOrderItem(obj){
                if(obj.id > 0){
                    this.entity.details.push(obj);
                }
                else{
                    obj.id = this.getFakeId(this.entity.details.length);
                    this.entity.details.push(obj);
                }
            },

            closeOrderEdit(obj){
                console.log(obj)
            },

            delOrderItem(id){
                const index = this.entity.details.findIndex(x => x.id == id);
                this.entity.details.splice(index, 1);
            },

            addOrderItem(){
                this.$refs.addForm.open(null);
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.entity.details !== undefined && this.entity.details.length > 0){
                    this.entity.details = this.entity.details.filter(item => !(item.goodsId == 0));
                    this.entity.details.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                        item.routes.forEach(function(item, index, self) {
                            if(!common.isNumeric(self[index].id)){
                                self[index].id = 0;
                            }
                        });
                    });
                }
                if(this.entity.details === undefined || this.entity.details.length === 0){
                    messageBox.showWarning("Vui lòng thêm hàng hóa");
                    return;
                }
                this.entity.status = 1;
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                orderHeavyService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$router.push("/order-heavy/crud/" + response.data.id);
                        this.id = response.data.id;
                        this.getById();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                orderHeavyService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.getById();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                orderHeavyService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.getProjectById();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            back(){
                if(parseFloat(this.$route.query.projectId) > 0){
                    this.$router.push('/project-heavy/crud/' + this.$route.query.projectId);
                }
                else{
                    this.$router.push('/order-heavy');
                }
            },

            getProjectById(){
                this.setLoading(true);
                projectService.getById(this.entity.projectId).then((response) => {
                    if(response.statusCode == 200){
                        this.entity.project = response.data;
                        this.contracts = response.data.contracts;
                        if(this.contracts.length > 0 && this.id == 0) {
                            this.entity.contractId = this.contracts[0].id;
                            this.entity.contract = this.contracts[0];
                        }
                        this.customers = [];
                        if(response.data.customer !== null){
                            this.entity.customerId = response.data.customer.id;
                            this.entity.customer = response.data.customer;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            onTabClick(tabIndex){
                this.tab = tabIndex;
            },

            //Staff
            closeStaff(item){
                this.staffName = item.fullName;
                this.entity.staffId = item.id;
                this.entity.deptId = item.departmentId,
                this.getCode(this.entity.staffId);
                this.$refs.staffList.close();
            },

            getStaffSelected(val){
                this.entity.staffId = val.id;
                this.entity.deptId = val.departmentId,
                this.getCode(this.entity.staffId);
                this.staffName = val.fullName;
            },

            openStaff(){
                this.$refs.staffList.open();
            },

            getStaffs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.staffs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            getByAccount(){
                this.setLoading(true);
                staffService.getByAccount().then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null){
                            this.currencyUserId = response.data.id;
                            if(this.entity.id == 0){
                                this.entity.staff = response.data;
                                this.entity.staffId = response.data.id;
                                this.entity.deptId = response.data.departmentId;
                                this.entity.branchId = response.data.department.branchId;
                                this.staffName = response.data.fullName;
                                this.getCode(this.entity.staffId);
                            }
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
        },
        watch: { 
            customerName: function (val){
                if(val == ''){
                    this.entity.customerId = 0;
                    this.entity.contractId = 0;
                    this.contractName = '';
                    this.entity.projectId = 0;
                    this.entity.projectName = '';
                    this.projectName = '';
                }
            },
            contractName: function (val) { 
                if(val == ''){
                    this.entity.contractId = 0;
                    this.entity.ownerId = 0;
                    this.ownerName = '';
                }
            },
            projectName: function (val) { 
                if(val == ''){
                    this.entity.projectId = 0;
                }
            },
            staffName: function (val) { 
                if(val == ''){
                    this.entity.staffId = 0;
                }
            },
        },
        validations: {
            entity: {
                orderCode: { required },
                branchId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                contractId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                customerId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                projectId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                staffId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                orderDate: { required },
                planId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
            }
        }
     })

</script>
