<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content style="width: 1200px;">
            <div class="page-content">
                <div class="grid">
                    <div class="row no-gutters page-list-header">
                        <div class="col l-6 m-6 c-12">
                            <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <div class="page-list-action"> 
                                <md-button v-shortkey="['ctrl', 's']" @click="submit()" class="md-raised md-primary">Lưu giải chi<md-tooltip>Giải chi</md-tooltip></md-button>
                                <md-button @click="close()" class="md-raised">Đóng<md-tooltip>Đóng</md-tooltip></md-button>
                            </div>
                        </div>
                    </div>
                    <div class="page-list-body">
                        <div class="form-body">
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="staffName">Nhân viên phụ trách</label>
                                        <md-input disabled  name="staffName" v-model="staffName"></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <md-field :class="{'md-invalid': submitted && $v.entity.estimateCode.$error }">
                                        <label for="code">Mã dự toán</label>
                                        <md-input disabled  name="code" v-model="entity.estimateCode"></md-input>
                                        <span class="md-error" v-if="submitted && !$v.entity.estimateCode.required">Vui lòng nhập mã dự toán</span>
                                    </md-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="code">Ngày dự toán</label>
                                        <md-input disabled  name="estimateDate" v-model="entity.estimateDate"></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <div style="display:flex; width: 100%;">
                                        <div style="width: 50%;">
                                            <md-field>
                                                <label for="note">Tổng dự toán</label>
                                                <md-input disabled name="pay" :value="formatNumber(entity.estimateAmount)" ></md-input>
                                            </md-field>
                                        </div>
                                        <div style="width: 50%; margin-left: 35px;">
                                            <md-field>
                                                <label for="note">Tổng giải chi</label>
                                                <md-input disabled name="payFee" :value="formatNumber(entity.explainAmount)" ></md-input>
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-body form-tab">
                            <div class="row">
                                <div class="col l-12 m-12 c-12">
                                    <md-tabs>
                                        <md-tab id="tab-detail" class="tab-content" md-label="Chi phí">
                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col l-12 m-12 c-12">
                                                        <div class="tool-bar">
                                                            
                                                        </div>
                                                        <div class="list" style="overflow-x: auto; width: 100%;">
                                                            <table class="data-table-2" style="width: 1730px !important;"> 
                                                                <thead> 
                                                                    <tr> 
                                                                        <th rowspan="2" style="width:70px;">#</th> 
                                                                        <th rowspan="2" style="width:120px;">Đơn vị</th>
                                                                        <th rowspan="2" style="width:230px;">Tên phí</th> 
                                                                        <th rowspan="2" style="width:120px;">Loại</th> 
                                                                        <th rowspan="2" style="width:100px;">Số lượng</th>
                                                                        <th colspan="3" style="width:320px; background-color: #96C9F4">Dự toán</th>
                                                                        <th colspan="6" style="width:720px; background-color: #F6E6CB">Giải chi</th>
                                                                    </tr> 
                                                                    <tr>
                                                                        <th style="width:120px; background-color: #96C9F4;">Đơn giá</th>
                                                                        <th style="width:80px; background-color: #96C9F4;">VAT</th>
                                                                        <th style="width:120px; background-color: #96C9F4;">Thành tiền</th>
                                                                        <th style="width:120px; background-color: #F6E6CB;">Đơn giá</th>
                                                                        <th style="width:80px; background-color: #F6E6CB;">VAT</th>
                                                                        <th style="width:150px; background-color: #F6E6CB;">Thành tiền</th>
                                                                        <th style="width:120px; background-color: #F6E6CB;">Số hóa đơn</th>
                                                                        <th style="width:150px; background-color: #F6E6CB;">Ngày hóa đơn</th>
                                                                        <th style="width:150px; background-color: #F6E6CB;">Ghi chú</th>
                                                                    </tr> 
                                                                </thead> 
                                                                <tbody v-if="loading == true" style="height: 100px;"> 
                                                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                                </tbody>
                                                                <tbody v-if="loading == false"> 
                                                                    <tr v-for="(item, index) in entity.details" :key="item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                        <td class="center">{{index + 1}}</td>
                                                                        <td class="center">
                                                                            {{ item.unit.unitName }}
                                                                        </td>
                                                                        <td class="left">
                                                                            {{ item.fee.feeName }}
                                                                        </td> 
                                                                        <td class="center">
                                                                            {{ getFeeType(item.type) }}
                                                                        </td>
                                                                        <td class="center">
                                                                            <span>{{ item.quantity }}</span>
                                                                        </td>
                                                                        <td class="right">
                                                                            <span>{{ formatNumber(item.estimatePrice) }}</span>
                                                                        </td>
                                                                        <td class="right">
                                                                            <span>{{ item.estimateVat }}%</span>
                                                                        </td>
                                                                        <td class="right">
                                                                            <span>{{ formatNumber(item.estimateAmount) }}</span>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div style="text-align: right;" v-if="item.jobEstimateId > 0">{{ formatNumber(item.explainPrice) }}</div>
                                                                            <div v-else class="group-control">
                                                                                <number @change="calSum(item)" v-model="item.explainPrice" :class="{'is-error': $v.entity.details.$each[index].explainPrice.$error}" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div style="text-align: right;" v-if="item.jobEstimateId > 0">{{ formatNumber(item.explainVat) }}</div>
                                                                            <div v-else class="group-control">
                                                                                <number @change="calSum(item)" v-model="item.explainVat" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div style="text-align: right;" v-if="item.jobEstimateId > 0">{{ formatNumber(item.explainAmount) }}</div>
                                                                            <div v-else class="group-control">
                                                                                <number :disabled="true" v-model="item.explainAmount" v-bind="currencyF" class="form-control currency"></number> 
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input v-model="item.billNumber" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <md-datepicker v-model="item.billDate" md-immediately :md-model-type="String"></md-datepicker>
                                                                            </div>
                                                                        </td>
                                                                        <td class="center">
                                                                            <div class="group-control">
                                                                                <input v-model="item.explainNote" class="form-control" type="text">
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody> 
                                                            </table> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </md-tab>
                                    </md-tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </md-content>
    </md-dialog>
</template>

<script>
import common from '../../../mixins';
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import messageBox from '../../../utils/messageBox';
import staffService from '../../../api/staffService';
import orderHeavyEstimateService from '../../../api/orderHeavyEstimateService';

export default ({
    data() {
        return {
            showDialog: false,
            id: 0,
            title: '',
            submitted: false,
            loading: false,
            staffName: '',
            staffs: [],
            numberF: common.numberF,
            vatF: common.vatF,
            currencyF: common.currencyF2,
            types: common.typeFee,
            order: null,
            entity: { id: 0, orderId: 0, order: { }, details: [], approveList: [], estimateCode: '', estimateDate: common.dateNow, staffId: 0, status: 0, amount: 0, vat: 0, pay: 0, note: '', payOnBehalf: 0, payFee: 0 },
        }
    },
    created(){
        this.entity.orderId = parseFloat(this.$route.params.id);
    },
    methods: {
        ...mapActions('common', ['setLoading']),

        calSummary(){
            this.entity.explainAmount = 0;
            for (var i = 0; i < this.entity.details.length; i++) {
                this.entity.explainAmount = this.entity.explainAmount + parseFloat(this.entity.details[i].explainAmount);
            }
        },

        calSum(item){
            const selected = this.entity.details.findIndex(x => x.id == item.id);
            let price = parseFloat(this.entity.details[selected].explainPrice);
            let vat =  parseFloat(this.entity.details[selected].explainVat) / 100;
            this.entity.details[selected].explainAmount = Math.round(price +  (price * vat)) * parseFloat(this.entity.details[selected].quantity);

            this.calSummary();
        },

        open(id){
            this.id = id;
            if(id > 0){
                this.title = 'Giải chi dự toán';
                this.getById();
            }
            this.showDialog = true;
        },
        close(){
            this.showDialog = false;
        },
        submit(){
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            if(this.id > 0){
                this.explain();
            }
        },

        explain(){
            this.setLoading(true);
            this.entity.isExplain = true;
            orderHeavyEstimateService.explain(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Cập nhật thành công");
                    this.$emit('refresh');
                    this.showDialog = false;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        getById(){
            this.setLoading(true);
            orderHeavyEstimateService.getById(this.id).then((response) => {
                if(response.statusCode == 200){
                    this.staffName = response.data.staff.fullName;
                    response.data.details.map((item) => {
                        if(response.data.isExplain == false){
                            item.explainPrice = item.estimatePrice;
                            item.explainVat = item.estimateVat;
                            item.explainAmount = item.estimateAmount;
                        }
                    });
                    this.entity = response.data;
                    this.calSummary();
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        getByAccount(){
            this.setLoading(true);
            staffService.getByAccount().then((response) => {
                if(response.statusCode == 200){
                    if(response.data != null){
                        this.entity.staff = response.data;
                        this.entity.staffId = response.data.id;
                        this.entity.deptId = response.data.departmentId;
                        this.staffName = response.data.fullName;
                        this.currentUser = response.data;
                        this.approve.staffId = response.data.id;
                        this.approve.staff = response.data;
                        this.approve.deptId = response.data.department.id;
                        this.approve.dept = response.data.department;
                        this.getCode(this.entity.staffId);
                    }
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },
    },
    validations: {
        entity: {
            estimateCode: { required },
            staffId: {
                required,
                isSelected(value) {
                    if (value > 0) {
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            },
            estimateDate: { required },
            details: {
                $each: {
                    feeId: {
                        required,
                        isSelected(value) {
                            if (value > 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    },
                    explainPrice: {
                        required,
                        isSelected(value) {
                            if (value > 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    },
                    explainAmount: {
                        required
                    },
                }
            }
        }
    }
})
</script>