<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content>
            <div class="dialog-header">
                <div class="dialog-title">{{ title }}</div>
                <div class="dialog-close" @click="showDialog = false">
                    Close
                </div>
            </div>
            <md-content class="md-scrollbar">
                <div class="dialog-content">
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.goodsCode.$error }">
                                <label for="goodsCode">Mã hàng hóa</label>
                                <md-input name="goodsCode" v-model="entity.goodsCode"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.goodsCode.required">Vui lòng nhập mã hàng hóa</span>
                            </md-field>

                            <md-field :class="{'md-invalid': submitted && $v.entity.goodsName.$error }">
                                <label for="goodsName">Tên hàng hóa</label>
                                <md-input name="goodsName" v-model="entity.goodsName"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.goodsName.required">Vui lòng nhập tên hàng hóa</span>
                            </md-field>
                        </div>
                    </div>
                </div>
            </md-content>
            <div class="dialog-actions">
                <md-checkbox v-if="id == 0" v-model="saveAndCreate" class="md-primary">Lưu và tạo mới</md-checkbox>
                <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                <md-button v-shortkey="['ctrl', 'đ']" @shortkey="showDialog = false" @click="showDialog = false" class="md-raised"><span>Đ</span>óng<md-tooltip>Đóng (Ctrl + Đ)</md-tooltip></md-button>
            </div>
        </md-content>
    </md-dialog>
</template>

<script>
    import goodsService from '../../../api/goodsService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';

    export default ({
        metaInfo: {
            title: 'Thêm/Cập nhật hàng hóa vận chuyển'
        },
        data() {
            return {
                title: '',
                id: 0,
                submitted: false,
                entity: { id: 0, goodsCode: '', goodsName: '', orderType: 1 },
                showDialog: false,
                saveAndCreate: false,
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            async open(id){
                this.id = id;
                if(id == 0){
                    this.title = 'Thêm mới hàng hóa vận chuyển';
                    this.typeName = '';
                    this.entity = { id: 0, goodsCode: '', goodsName: '', orderType: 1 };
                }
                else {
                    this.saveAndCreate = false;
                    this.title = 'Cập nhật hàng hóa vận chuyển';
                    this.getById();
                }
                this.submitted = false;
                this.showDialog = true;
            },

            close(){
                this.showDialog = false;
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                this.entity.orderType = 1;
                goodsService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$emit('close');
                        if(!this.saveAndCreate){
                            this.showDialog = false;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                goodsService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$emit('close');
                        this.showDialog = false;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                goodsService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
        },
        validations: {
            entity: {
                goodsCode: { required },
                goodsName: { required }
            }
        }
     })

</script>

<style lang="css" scoped>
    .dialog-title {
        font-size: 16px;
    }
    .dialog-header {
        border-bottom: 1px solid #ccc;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 15px;
    }
    .md-content {
        width: 600px;
        height: 300px;
        max-width: 600px;
    }
    .md-scrollbar {
        height: 290px;
        padding-bottom: 20px;
    }
    .dialog-content {
        padding: 10px 15px;
    }
    .dialog-actions {
        height: 35px;
        border-top: 1px solid #ccc;
        padding-top: 15px;
        display: flex;
        align-items: center;
        justify-content: end;
    }
</style>