<template>
    <div>
        <div class="row">
            <div class="col l-3 m-3 c-12">
                <md-field :class="{'md-invalid': submitted && $v.entity.shipmentCode.$error }">
                    <label for="shipmentCode">Số shipment<span class="label-require">(*)</span></label>
                    <md-input name="shipmentCode" v-model="entity.shipmentCode" ></md-input>
                    <span class="md-error" v-if="submitted && !$v.entity.shipmentCode.required">Vui lòng nhập mã shipment</span>
                </md-field>
                <md-field>
                    <label for="shipNo">Số chuyến</label>
                    <md-input name="shipNo" v-model="entity.shipmentNum" ></md-input>
                </md-field>
            </div>
            <div class="col l-3 m-3 c-12">
                <div class="form-control">
                    <md-autocomplete v-model="shipmentName" @md-selected="getShipSelected" :md-options="ships" @md-changed="getShips"  @md-opened="getShips">
                        <label>Hãng tàu</label>
                        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                            <md-highlight-text :md-term="term">{{ item.companyName }}</md-highlight-text>
                        </template>
                        <template slot="md-autocomplete-empty" slot-scope="{ term }">
                            "{{ term }}" Không tìm thấy!
                        </template>
                    </md-autocomplete>
                    <md-button @click="openShip()" class="md-icon-button">
                        <md-icon>manage_search</md-icon>
                        <md-tooltip>Tìm hãng tàu</md-tooltip>
                    </md-button>
                </div>

                <md-field>
                    <label for="shipmentName">Tên tàu</label>
                    <md-input name="shipmentName" v-model="entity.shipmentName" ></md-input>
                </md-field>
            </div>
            <div class="col l-3 m-3 c-12">
                <md-datepicker v-model="entity.dateOfShipCome" md-immediately :md-model-type="String">
                    <label>Ngày tàu đến</label>
                </md-datepicker>
                <md-datepicker v-model="entity.dateOfShipGo" md-immediately :md-model-type="String">
                    <label>Ngày tàu đi</label>
                </md-datepicker>
            </div>
            <div class="col l-3 m-3 c-12">
                <md-field>
                    <label for="contactName">Người liên hệ</label>
                    <md-input name="contactName" v-model="entity.contactName" ></md-input>
                </md-field>
                <md-field>
                    <label for="contactPhone">Số điện thoại</label>
                    <md-input name="contactPhone" v-model="entity.contactPhone"></md-input>
                </md-field>
            </div>
        </div>
        <div class="row">
            <div style="display: flex; justify-content: start; width: 100%; padding-top: 20px;">
                <md-button @click="submit(1)" class="md-raised md-primary"><span>L</span>ưu Shipment<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
            </div>
        </div>
        <shipList ref="shipList" title="hãng tàu" :type="companyType" @close="closeShip"/>
    </div>
</template>

<script>
    import shipList from '../../../components/popup/_CompanyList.vue';
    import common from '../../../mixins';
    import { mapActions } from 'vuex';
    import messageBox from '../../../utils/messageBox';
    import companyService from '../../../api/companyService';
    import shipmentService from '../../../api/shipmentService';
    import { required } from 'vuelidate/lib/validators';

    export default ({
        components: {
            shipList
        },
        data() {
            return {
                submitted: false,
                loadding: false,
                companyType: common.companyType.shipping,
                orderId: 0,
                shipmentName: '',
                ships: [],
                timeOnly: null,
                entity: { id: 0, orderId: 0, shipmentId: null, shipmentName: '', shipmentCode: '', contactName: '', contactPhone: '', dateOfShipCome: '', dateOfShipGo: '' },
            }
        },
        created(){
            this.orderId = this.$route.params.id;
            if(this.orderId > 0){
                this.getByOrderId();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.entity.orderId = this.$route.params.id;
                if(this.entity.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                shipmentService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        messageBox.showMessage("Cập nhật thành công");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                shipmentService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getByOrderId(){
                this.setLoading(true);
                shipmentService.getByOrderId(this.orderId).then((response) => {
                    if(response.statusCode == 200){
                        if(response.data !== null){
                            this.entity = response.data;
                            if(this.entity.shipmentId > 0){
                                this.shipmentName = response.data.company.companyAlias;
                            }
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Ship
            closeShip(item){
                this.shipmentName = item.companyAlias;
                this.entity.shipmentId = item.id;
                this.$refs.shipList.close();
            },

            getShipSelected(val){
                this.entity.shipmentId = val.id;
                this.shipmentName = val.companyAlias;
            },

            openShip(){
                this.$refs.shipList.open(this.companyType);
            },

            getShips(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: common.companyType.shipping, code: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.ships = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            }
        },
        watch: { 
            shipmentName: function (val) { 
                if(val == ''){
                    this.entity.shipmentId = 0;
                }
            }
        },
        validations: {
            entity: {
                shipmentCode: { required }
            }
        }
    })
</script>