<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content>
            <div class="dialog-header">
                <div class="dialog-title">{{ title }}</div>
                <div class="dialog-close" @click="showDialog = false">
                    Close
                </div>
            </div>
            <md-content class="md-scrollbar">
                <div class="dialog-content">
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-field :class="{'md-invalid': submitted && $v.entity.typeOfTransport.$error }">
                                    <label>Loại hình<span class="label-require">(*)</span></label>
                                    <md-select v-model="entity.typeOfTransport">
                                        <md-option v-for="item in typeList" :key="'type-' + item.id" :value="item.id">{{item.text}}</md-option>
                                    </md-select>
                                    <span class="md-error" v-if="submitted && !$v.entity.typeOfTransport.isSelected">Vui lòng chọn loại hình vận chuyển</span>
                                </md-field>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-field :class="{'md-invalid': submitted && $v.entity.goodsTypeId.$error }">
                                    <label>Loại hàng<span class="label-require">(*)</span></label>
                                    <md-select v-model="entity.goodsTypeId">
                                        <md-option v-for="item in goodsTypeList" :key="'type-' + item.id" :value="item.id">{{item.text}}</md-option>
                                    </md-select>
                                    <span class="md-error" v-if="submitted && !$v.entity.goodsTypeId.isSelected">Vui lòng chọn hàng</span>
                                </md-field>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="unitName" @md-selected="getUnitSelected" :md-options="units" @md-changed="getUnits"  @md-opened="getUnits" :class="{'md-invalid': submitted && $v.entity.unitId.$error }">
                                    <label>Packing type<span class="label-require">(*)</span></label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.unitName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.unitId.isSelected">Vui lòng chọn đợn vị hàng hóa</span>
                                </md-autocomplete>
                                <md-button @click="openUnit()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>  
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="goodsName" @md-selected="getGoodsSelected" :md-options="goods" @md-changed="getGoods"  @md-opened="getGoods" :class="{'md-invalid': submitted && $v.entity.goodsId.$error }">
                                    <label>Hàng hóa<span class="label-require">(*)</span></label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.goodsName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.goodsId.isSelected">Vui lòng chọn hàng hóa</span>
                                </md-autocomplete>
                                <md-button @click="openGoods()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm hàng hóa</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.pkgNo.$error }">
                                <label for="pkgNo">PKG No<span class="label-require">(*)</span></label>
                                <md-input name="pkgNo" v-model="entity.pkgNo"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.pkgNo.required">Vui lòng nhập pkg no</span>
                            </md-field>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-field>
                                <label for="kg">Khối lượng(kg)</label>
                                <md-input name="kg" v-model="entity.kg"></md-input>
                            </md-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <md-field>
                                <label for="length">Chiều dài (cm)</label>
                                <md-input name="length" v-model="entity.length"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-field>
                                <label for="width">Chiều rộng</label>
                                <md-input name="width" v-model="entity.width"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-field>
                                <label for="height">Chiều cao</label>
                                <md-input name="height" v-model="entity.height"></md-input>
                            </md-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <md-datepicker v-model="entity.dateOfDelivery" md-immediately :md-model-type="String">
                                <label>Ngày giao/nhận</label>
                            </md-datepicker>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-field>
                                <label for="booking">Số booking</label>
                                <md-input name="booking" v-model="entity.bookingNo"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="row">
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="serial">Số cont</label>
                                        <md-input name="serial" v-model="entity.serial"></md-input>
                                    </md-field>
                                </div>
                                <div class="col l-6 m-6 c-12">
                                    <md-field>
                                        <label for="seal">Số seal</label>
                                        <md-input name="seal" v-model="entity.seal"></md-input>
                                    </md-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <div class="group-box" style="margin-top:10px; padding-bottom: 0;">
                                <div class="group-title">THÔNG TIN HÀNH TRÌNH</div>
                                <div class="box-content">
                                    <div class="tool-bar" style="margin-top: 5px; padding-bottom: 0;">
                                        <md-button @click="addRoute()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                    </div>
                                    <table class="data-table-2"> 
                                        <thead> 
                                            <tr> 
                                                <th style="width:100px;">Hành động</th>
                                                <th style="width:150px;">Thứ tự</th>
                                                <th style="width:150px;">Loại địa điểm</th>
                                                <th style="width:60%;">Địa điểm<span class="label-require">(*)</span></th> 
                                            </tr> 
                                        </thead> 
                                        <tbody v-if="loading == true" style="height: 50px;"> 
                                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                        </tbody>
                                        <tbody v-if="loading == false"> 
                                            <tr v-for="(item, index) in entity.routes" :key="'route-' + index" :class="{ 'odd': index % 2 !== 0 }"> 
                                                <td class="grid-action">
                                                    <a @click="delRoute(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                </td>
                                                <td class="center">
                                                    {{ (index + 1) }}
                                                </td>
                                                <td class="center">
                                                    <md-field>
                                                        <md-select v-model="item.type" name="type">
                                                            <md-option v-for="item in routeTypeList" :key="'location-type-' + item.id" :value="item.id">{{item.text}}</md-option>
                                                        </md-select>
                                                    </md-field>
                                                </td>
                                                <td class="center">
                                                    <div class="grid-control">
                                                        <input v-model="item.location.locationName" :class="{'is-error': $v.entity.routes.$each[index].locationId.$error}" class="form-control" type="text">
                                                        <md-button @click="openLocation(item.id)" class="md-icon-button">
                                                            <md-icon>manage_search</md-icon>
                                                            <md-tooltip>Tìm địa điểm</md-tooltip>
                                                        </md-button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody> 
                                    </table> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </md-content>
            <div class="dialog-actions">
                <md-checkbox v-if="id == 0" v-model="saveAndCreate" class="md-primary">Lưu và tạo mới</md-checkbox>
                <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                <md-button v-shortkey="['ctrl', 'đ']" @shortkey="showDialog = false" @click="showDialog = false" class="md-raised"><span>Đ</span>óng<md-tooltip>Đóng (Ctrl + Đ)</md-tooltip></md-button>
            </div>
        </md-content>
        <unitList ref="unitList" @close="closeUnit"/>
        <goodsList ref="goodsList" @close="closeGoods"/>
        <locationList ref="locationList" @close="closeLocation"/>
    </md-dialog>
</template>

<script>
    import goodsService from '../../../api/goodsService';
    import unitService from '../../../api/unitService';
    import feeService from '../../../api/feeService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '../../../mixins'; 
    import unitList from '../../../components/popup/_UnitList.vue';
    import goodsList from '../../../components/popup/_GoodsList.vue';
    import locationList from '../../../components/popup/_LocationList.vue';

    export default ({
        components: {
            unitList,
            goodsList,
            locationList
        },
        data() {
            return {
                showDialog: false,
                loading: false,
                title: '',
                id: 0,
                submitted: false,
                routeTypeList: common.routeType,
                goodsTypeList: common.goodsTypeHevayList,
                typeList: common.typeOfTransportHeavy,
                entity: { id: 0, routes: [], unitId: 0, goodsId: 0 },
                saveAndCreate: false,
                unitName: '',
                units: [],
                goodsName: '',
                goods: [],
                locationName: '',
                locations: [],
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            addRoute() {
                this.entity.routes.push(
                    {
                        id: this.uuidv4(this.entity.routes.length + 1), 
                        type: 1,
                        locationId: 0,
                        location: { locationName: '' }
                    }
                );
            },  

            delRoute(item){
                const index = this.entity.routes.findIndex(x => x.id == item.id);
                this.entity.routes.splice(index, 1);
            },

            async open(item){
                this.submitted = false;
                this.goodsName = "";
                this.unitName = "";
                if(item === null){
                    this.title = 'Thêm mới kiện hàng';
                    this.entity = { id: 0, serial: '', seal: '', routes: [], unitId: 0, goodsId: 0, typeOfTransport: 0, goodsTypeId: 0, pkgNo: '', dateOfDelivery: '', bookingNo: '' };
                    this.addRoute();
                }
                else {
                    this.saveAndCreate = false;
                    this.title = 'Cập nhật kiện hàng';
                    this.entity = item;
                    this.unitName = item.unit.unitName;
                    this.goodsName = item.goods.goodsName;
                }
                this.showDialog = true;
            },

            close(){
                this.showDialog = false;
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.entity.routes === undefined || this.entity.routes.length === 0){
                    messageBox.showWarning("Vui lòng thêm hành trình");
                    return;
                }
                if(this.entity.id > 0){
                    this.$emit('edit', this.entity);
                }
                else{
                    this.$emit('close', this.entity);
                }
                if(!this.saveAndCreate){
                    this.showDialog = false;
                }
                this.id = 0;
                this.submitted =  false;
            },

            getById(){
                this.setLoading(true);
                feeService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Location
            closeLocation(item){
                const selected = this.entity.routes.findIndex(x => x.id == this.selectedId);
                this.entity.routes[selected].location = item;
                this.entity.routes[selected].locationId = item.id;
                this.$refs.locationList.close();
                this.selectedId = '';
            },

            openLocation(id){
                this.selectedId = id;
                this.$refs.locationList.open();
            },

            //Goods
            closeGoods(item){
                this.goodsName = item.goodsName;
                this.entity.goods = item;
                this.entity.goodsId = item.id;
                this.$refs.goodsList.close();
            },

            getGoodsSelected(val){
                this.entity.goods = val;
                this.entity.goodsId = val.id;
                this.goodsName = val.goodsName;
            },

            openGoods(){
                this.$refs.goodsList.open(2);
            },

            getGoods(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 2, code:  searchTerm };
                goodsService.getGoodss(search).then((response) => {
                    if(response.statusCode == 200){
                        this.goods = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Unit
            closeUnit(item){
                this.entity.unit = item;
                this.unitName = item.unitName;
                this.entity.unitId = item.id;
                this.$refs.unitList.close();
            },

            getUnitSelected(val){
                this.entity.unitId = val.id;
                this.unitName = val.unitName;
                this.entity.unit = val;
            },

            openUnit(){
                this.$refs.unitList.open(2);
            },

            getUnits(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 2, code:  searchTerm };
                unitService.getUnits(search).then((response) => {
                    if(response.statusCode == 200){
                        this.units = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            uuidv4(id) {
                let uuid = "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
                    (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
                );

                return uuid + "-" + id;
            },
        },
        validations: {
            entity: {
                typeOfTransport: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                goodsTypeId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                goodsId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                unitId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                pkgNo: { required },
                routes: {
                    $each: {
                        locationId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        }
                    }
                }
            }
        }
     })

</script>

<style lang="css" scoped>
    .dialog-title {
        font-size: 16px;
    }
    .dialog-header {
        border-bottom: 1px solid #ccc;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 15px;
    }
    .md-content {
        width: 1000px;
        height: 730px;
        max-width: 1000px;
    }
    .md-scrollbar {
        height: 710px;
        padding-bottom: 20px;
    }
    .dialog-content {
        padding: 10px 15px;
    }
    .dialog-actions {
        height: 35px;
        border-top: 1px solid #ccc;
        padding-top: 15px;
        display: flex;
        align-items: center;
        justify-content: end;
    }
</style>