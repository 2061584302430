<template>
    <div class="table-content" style="padding: 0">
        <div style="padding-left: 0;">
            <md-button style="margin-left: 0;" v-shortkey="['ctrl', 'm']" @shortkey="add()" @click="add()" class="md-raised md-primary btn-add">Thêm <span>m</span>ới<md-tooltip>Thêm mới (Ctrl + T)</md-tooltip></md-button>
        </div>
        <table class="data-table" style="min-height: 50px !important;">
            <thead>
                <tr> 
                    <th style="width:60px;">#</th> 
                    <th class="left" style="width:25%;">Mã điều động</th>
                    <th style="width:15%;">Ngày thực hiện</th>
                    <th class="right" style="width:15%;">Tổng dự toán</th>
                    <th style="width:15%;">Đã dự toán</th>
                    <th style="width:15%;">Giải chi</th>
                    <th style="width:15%;">Quyết toán</th>
                    <th style="width:10%;">Hành động</th>
                </tr>
            </thead>
            <tbody v-if="loadding == true" style="height: 150px;">
                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
            </tbody>
            <tbody v-if="loadding == false && data.length == 0" style="height: 150px;">
                <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
            </tbody>
            <tbody v-if="loadding == false && data.length > 0">
                <tr v-for="(item, index) in data" :key="'job-' + index">
                    <td class="center">
                        {{ index + 1}}
                    </td> 
                    <td class="left">
                        {{ item.jobCode }}
                    </td>
                    <td class="center">
                        {{ item.jobDate }}
                    </td>
                    <td class="right">
                        {{ formatNumber(item.amountOfOrderDetail) }}
                    </td>
                    <td class="center">
                        <md-chip  v-if="item.inOrderEstimate" class="md-primary">Đã Dự toán</md-chip>
                        <md-chip v-else>Chưa dự toán</md-chip>
                    </td>
                    <td class="center">
                        <md-chip  v-if="item.isExplain" class="md-primary">Đã giải chi</md-chip>
                        <md-chip v-else-if="!item.isExplain">Chưa giải chi</md-chip>
                        <span v-else>N/A</span>
                    </td>
                    <td class="center">
                        <md-chip  v-if="item.isInvoice" class="md-primary">Đã QT</md-chip>
                        <md-chip v-else>Chưa QT</md-chip>
                    </td>
                    <td class="action">
                        <md-button v-if="item.isInvoice == false" @click="explain(item.id)" class="md-fab md-mini md-view">
                            <i class="uil uil-usd-circle"></i>
                            <md-tooltip>Giải chi</md-tooltip>
                        </md-button>
                        <md-button v-if="item.isExplain == false && item.isInvoice == false && item.inOrderEstimate == false" @click="edit(item.id)" class="md-fab md-mini md-second">
                            <md-icon>edit</md-icon>
                            <md-tooltip>Sửa</md-tooltip>
                        </md-button>
                        <md-button v-else disabled class="md-fab md-mini md-second">
                            <md-icon>edit</md-icon>
                            <md-tooltip>Sửa</md-tooltip>
                        </md-button>
                        <md-button v-if="item.isExplain == false && item.isInvoice == false && item.inOrderEstimate == false" v-on:click="confirmDelete(item.id)" class="md-fab md-mini md-plain">
                            <md-icon>clear</md-icon>
                            <md-tooltip>Xóa</md-tooltip>
                        </md-button>
                        <md-button v-else disabled class="md-fab md-mini md-plain">
                            <md-icon>clear</md-icon>
                            <md-tooltip>Xóa</md-tooltip>
                        </md-button>
                    </td>
                </tr>
            </tbody>
        </table>
        <orderVehicleForm ref="orderVehicleForm" @close="refresh" />
    </div>
</template>
<script>
    import jobHeavyService from '../../../api/jobHeavyService';
    import messageBox from '../../../utils/messageBox';
    import { mapActions } from 'vuex';
    import orderVehicleForm from '../order-vehicle/Crud.vue'

    export default {
        props: ["order"],
        components: {
            orderVehicleForm
        },
        data() {
            return {
               loadding: false,
               data: [],
               id: 0
            }
        },
        created(){
            this.getAll();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            refresh(){
                this.getAll();
            },

            filter(){
                this.getAll();
            },

            getAll(){
                this.loadding = true;
                jobHeavyService.getAll(this.order.id).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            confirmDelete(id){
                this.$modal.show('dialog', {
                title: 'Thông báo',
                text: 'Bạn có chắc muốn xóa không?',
                buttons: [
                        {
                            title: 'Hủy',
                            handler: () => {
                                this.$modal.hide('dialog')
                            }
                        },
                        {
                            title: 'Xóa',
                            handler: () => {
                                this.del(id)
                            }
                       }
                ]
                })
            },

            del(id){
                this.$modal.hide('dialog');
                this.setLoading(true);
                jobHeavyService.delete(id).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Xóa thành công");
                        this.refresh();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            add(){
                this.$refs.orderVehicleForm.open(this.order);
            },

            edit(id){
                this.$refs.orderVehicleForm.openEdit(id);
            },

            explain(id){
                this.$refs.orderVehicleForm.openExplain(id);
            },
        },
        watch: {
            'search.pageIndex': function () {
                this.filter();
            },
            'search.pageSize': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
        }
    }

</script>
