<template>
    <div>
        <div class="row">
            <div class="col l-3 m-3 c-12">
                <div class="form-control">
                    <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                        <label>Nhân viên khai báo<span class="label-require">(*)</span></label>
                        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                            <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                        </template>
                        <template slot="md-autocomplete-empty" slot-scope="{ term }">
                            "{{ term }}" Không tìm thấy!
                        </template>
                        <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn nhân viên khai báo</span>
                    </md-autocomplete>
                    <md-button @click="openStaff()" class="md-icon-button">
                        <md-icon>manage_search</md-icon>
                        <md-tooltip>Tìm nhân viên</md-tooltip>
                    </md-button>
                </div>
                <div class="form-control">
                    <md-autocomplete v-model="companyName" @md-selected="getCompanySelected" :md-options="companies" @md-changed="getCompanies"  @md-opened="getCompanies" :class="{'md-invalid': submitted && $v.entity.companyId.$error }">
                        <label>Cơ quan hải quan<span class="label-require">(*)</span></label>
                        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                            <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                        </template>
                        <template slot="md-autocomplete-empty" slot-scope="{ term }">
                            "{{ term }}" Không tìm thấy!
                        </template>
                        <span class="md-error" v-if="submitted && !$v.entity.companyId.isSelected">Vui lòng chọn nhân viên khai báo</span>
                    </md-autocomplete>
                    <md-button @click="openCompany()" class="md-icon-button">
                        <md-icon>manage_search</md-icon>
                    </md-button>
                </div>
            </div>
            <div class="col l-3 m-3 c-12">
                <md-field :class="{'md-invalid': submitted && $v.entity.declarationCode.$error }">
                    <label for="declarationCode">Số tờ khai<span class="label-require">(*)</span></label>
                    <md-input name="declarationCode" v-model="entity.declarationCode" ></md-input>
                    <span class="md-error" v-if="submitted && !$v.entity.declarationCode.required">Vui lòng nhập số tờ khai</span>
                </md-field>

                <md-datepicker v-model="entity.declarationDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.declarationDate.$error }">
                    <label>Ngày tờ khai<span class="label-require">(*)</span></label>
                    <span class="md-error" v-if="submitted && !$v.entity.declarationDate.required">Vui lòng chọn ngày tờ khai</span>
                </md-datepicker>
            </div>
            <div class="col l-3 m-3 c-12">
                <div class="form-control">
                    <md-autocomplete v-model="categoryName" @md-selected="getCategorySelected" :md-options="categories" @md-changed="getCategories"  @md-opened="getCategories">
                        <label>Loại hình xuât/nhập khẩu<span class="label-require">(*)</span></label>
                        <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                            <md-highlight-text :md-term="term">{{ item.categoryCode + "-" + item.categoryName }}</md-highlight-text>
                        </template>
                        <template slot="md-autocomplete-empty" slot-scope="{ term }">
                            "{{ term }}" Không tìm thấy!
                        </template>
                    </md-autocomplete>
                    <md-button @click="openCategory()" class="md-icon-button">
                        <md-icon>manage_search</md-icon>
                    </md-button>
                </div>

                <md-datepicker v-model="entity.clearanceDate" md-immediately :md-model-type="String">
                    <label>Ngày thông quan</label>
                </md-datepicker>
            </div>
            
            <div class="col l-3 m-3 c-12">
                <md-field :class="{'md-invalid': submitted && $v.entity.thread.$error }">
                    <label for="thread">Phân luồng<span class="label-require">(*)</span></label>
                    <md-select v-model="entity.thread" name="thread" id="thread">
                        <md-option :value="0">Chưa phân luồng</md-option>
                        <md-option :value="3">Luồng xanh</md-option>
                        <md-option :value="1">Luồng vàng</md-option>
                        <md-option :value="2">Luồng đỏ</md-option>
                    </md-select>
                </md-field>
                <md-field :class="{'md-invalid': submitted && $v.entity.status.$error }">
                    <label for="status">Trạng thái<span class="label-require">(*)</span></label>
                    <md-select v-model="entity.status" name="status" id="status">
                        <md-option :value="0">Chưa mở tờ khai</md-option>
                        <md-option :value="1">Mở tờ khai</md-option>
                        <md-option :value="2">Phân luồng tờ khai</md-option>
                        <md-option :value="3">Đăng ký hải quan</md-option>
                        <md-option :value="4">Không làm hải quan</md-option>
                        <md-option :value="5">Thông quan</md-option>
                    </md-select>
                </md-field>
            </div>
        </div>
        <div class="row">
            <div style="display: flex; justify-content: start; width: 100%; padding-top: 20px;">
                <md-button @click="submit(1)" class="md-raised md-primary"><span>L</span>ưu khai báo hải quan<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
            </div>
        </div>
        
        <staffList ref="staffList" @close="closeStaff"/>
        <companyList ref="companyList" title="cơ quan hải quan" :type="companyType" @close="closeCompany"/>
        <categoryList ref="categoryList" @close="closeCategory"/>
    </div>
</template>

<script>
    import common from '../../../mixins';
    import { mapActions } from 'vuex';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import customsHeavyService from '../../../api/customsHeavyService';
    import companyService from '../../../api/companyService';
    import staffService from '../../../api/staffService';
    import staffList from '../../../components/popup/_StaffList.vue';
    import companyList from '../../../components/popup/_CompanyList.vue';
    import categoryService from '../../../api/categoryService';
    import categoryList from '@/components/popup/_CategoryXNKTypeList';

    export default ({
        components: {
            staffList,
            companyList,
            categoryList
        },
        data() {
            return {
                submitted: false,
                loadding: false,
                companyType: common.companyType.customs,
                groupType: common.groupCode.customsType,
                entity: { id: 0, staffId: 0, companyId: 0, orderId: 0, categoryId: null, declarationCode: '', declarationDate: common.dateNow, clearanceDate: '', thread: 0, status: 0 },
                staffName: '',
                staffs: [],
                companies: [],
                companyName: '',
                categories: [],
                categoryName: '',
                orderId: 0
            }
        },
        created(){
            this.orderId = this.$route.params.id;
            if(this.orderId > 0){
                this.getByOrderId();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.entity.orderType = 1;
                this.entity.orderId = this.$route.params.id;
                this.entity.thread = parseInt(this.entity.thread);
                this.entity.status = parseInt(this.entity.status);
                if(this.entity.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                customsHeavyService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        messageBox.showMessage("Cập nhật thành công");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                customsHeavyService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getByOrderId(){
                this.setLoading(true);
                customsHeavyService.getByOrderId(this.orderId).then((response) => {
                    if(response.statusCode == 200){
                        if(response.data !== null){
                            this.entity = response.data;
                            this.staffName = response.data.staff.fullName;
                            this.orderName = response.data.order.orderCode;
                            this.companyName = response.data.company.companyAlias;
                            this.categoryName = response.data.category.categoryCode + "-" + response.data.category.categoryName;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Category
            closeCategory(item){
                this.categoryName = item.categoryCode + "-" + item.categoryName;
                this.entity.categoryId = item.id;
                this.$refs.categoryList.close();
            },

            getCategorySelected(item){
                this.entity.categoryId = item.id;
                this.categoryName = item.categoryCode + "-" + item.categoryName;
            },

            openCategory(){
                this.$refs.categoryList.open();
            },

            getCategories(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, type: 5, code: searchTerm };
                categoryService.getCategories(search).then((response) => {
                    if(response.statusCode == 200){
                        this.categories = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Company
            closeCompany(item){
                this.companyName = item.companyAlias;
                this.entity.companyId = item.id;
                this.$refs.companyList.close();
            },

            getCompanySelected(val){
                this.entity.companyId = val.id;
                this.companyName = val.companyAlias;
            },

            openCompany(){
                this.$refs.companyList.open(11);
            },

            getCompanies(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, orderType: this.orderType, companyType: common.companyType.customs, code: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.companies = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },


            //Staff
            closeStaff(item){
                this.staffName = item.fullName;
                this.entity.staffId = item.id;
                this.entity.deptId = item.departmentId,
                this.$refs.staffList.close();
            },

            getStaffSelected(val){
                this.entity.staffId = val.id;
                this.entity.deptId = val.departmentId,
                this.staffName = val.fullName;
            },

            openStaff(){
                this.$refs.staffList.open();
            },

            getStaffs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.staffs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },
        },
        watch: { 
            companyName: function (val) { 
                if(val == ''){
                    this.entity.companyId = 0;
                }
            },
            staffName: function (val) { 
                if(val == ''){
                    this.entity.staffId = 0;
                }
            },
            categoryName: function (val) { 
                if(val == ''){
                    this.entity.categoryId = null;
                }
            }
        },
        validations: {
            entity: {
                staffId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                companyId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                declarationCode: {required},
                declarationDate: { required },
                thread: { required },
                status: { required },
            }
       }
    })
</script>