import httpClient  from '.'; 
const API_CONTROLLER = 'shipment/'; 


export default { 
    delete(id){ 
        let url = API_CONTROLLER + 'delete';
        return httpClient.delete(url, {
            params: {
                id: id
            }
        });
    },

    add(data) { 
        let url = API_CONTROLLER + 'add';
        return httpClient.post(url, data);
    },

    edit(data) { 
        let url = API_CONTROLLER + 'edit';
        return httpClient.post(url, data);
    },

    getById(id) { 
        let url = API_CONTROLLER + 'get-by-id';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },

    getByOrderId(orderId) { 
        let url = API_CONTROLLER + 'get-by-order-id';
        return httpClient.get(url, {
            params: {
                orderId: orderId
            }
        });
    },

    filter(search) { 
        let url = API_CONTROLLER + 'filter';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                orderType: search.orderType,
                status: search.status,
                bookingNo: search.code
            }
        });
    },

    getBookings(search) { 
        let url = API_CONTROLLER + 'get-bookings';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                orderType: search.orderType,
                code: search.code
            }
        });
    },

    getBookingGoods(bookingId) { 
        let url = API_CONTROLLER + 'get-booking-goods';
        return httpClient.get(url, {
            params: {
                bookingId: bookingId
            }
        });
    },
}